import { IPriceCard } from '../shared/contentfu.interface';
import { LinkButton } from '../shared/button';
import navigateTo from '../../locales/pathHandler';
import React from 'react';
import styled from '@emotion/styled';
import { theme } from '../shared/theme';

import { Card, Divider } from 'antd';
import { TextRegular, TextSmall, TextXsmall } from '../shared/typography';

const StyledPrice = styled.h4`
  text-align: center;
  font-size: 60px;
  font-weight: 400;
  color: ${theme.colors.brandLight.dark};
  margin: 0;
`;

const StyledValue = styled.div`
  background: ${theme.colors.brandLight.third};
  padding: 0.25rem 0.75rem;
  position: absolute;
  top: -15px;
  width: 100px;
  left: calc(50% - 50px);
`

const StyledCard = styled(Card)`
  height: 100%;
  min-width: 200px;
  border-radius: 2px;
  margin: 0.5rem;
  border: 1px solid ${theme.colors.brandLight.forth};
  .ant-card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 1rem;
    padding-right: 1rem;
    border: none;
  }
  transition: transform .2s;

  &:hover {
    transform: scale(1.1);
}
`;


type Props = {
  priceCard: IPriceCard
  locale: string
}

const PriceingCard: React.FC<Props> = ({priceCard, locale}: Props) => {
  return (
    <StyledCard>
      {priceCard.highlight && <StyledValue><TextSmall style={{textAlign: 'center', color: "white", margin: 0}}>Best Value</TextSmall></StyledValue>}
      <TextRegular style={{textAlign: 'center'}}>{priceCard.title}</TextRegular>
      <StyledPrice>{priceCard.price}<TextXsmall style={{display: 'inline'}}>DKK</TextXsmall></StyledPrice>
      <TextSmall style={{textAlign: 'center', minHeight: '80px'}}>{priceCard.description ? priceCard.description.description : ''}</TextSmall>
      <LinkButton onClick={() => navigateTo({locale: locale}, 'contact')}>
        {' '}
        {priceCard.linkButton}
      </LinkButton>
      <Divider />
       {priceCard.features && priceCard.features.map((feature: string, key: number) => {
         return (
           <TextSmall key={key} style={{textAlign: "center"}}>{feature}</TextSmall>
         )
       })}
    </StyledCard>
  );
};
export default PriceingCard;
