import FadeInContainer from '../components/shared/sectionAnimation';
import FadeInHeader from '../components/shared/textAnnimation';
import { graphql } from 'gatsby';
import { PageContext } from '../pageContext';
import Partners from '../components/shared/partners';
import PriceingCard from '../components/services/priceing';
import React from 'react';
import ReviewCarousel from '../components/index/ReviewCarousel';
import Seo from '../components/Seo';
import SiteLayout from '../components/layout/layout';
import styled from '@emotion/styled';
import { theme } from '../components/shared/theme';

import {
  AimOutlined,
  FieldTimeOutlined,
  PushpinOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import { Col, Row } from 'antd';
import { H1, H2, H2Bold, TextXsmall } from '../components/shared/typography';
import {
  ICompanyPage,
  IPriceCard,
} from '../components/shared/contentfu.interface';
import Img, { GatsbyImageFluidProps } from "gatsby-image"

const StyledHeadline = styled.h1`
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 38px;
  line-height: 1.23;
  text-align: center;
  padding: 0 1rem;
  margin-top: 1rem;

  @media (max-width: 700px) {
    font-size: 25px;
  }
`;


const CompanyImg = styled(Img)`
height: 550px;
object-fit: cover;
object-position: center;

@media (max-width: 700px) {
    height: 350px;
  }
`;

const StyledHead = styled(H2)`
  color: ${theme.colors.brandLight.primary};
  margin-top: 2rem;
  margin-bottom: 2rem;
  text-align: left;
  @media (max-width: 995px) {
    text-align: center;
    }
`;



interface Props {
  data: {
    allContentfulCompanyPage: {
      nodes: ICompanyPage[];
    }
  },
  pageContext: PageContext
}

const CompanyPage: React.FC<Props> = ({ data, pageContext }: Props) => {
  const content = data.allContentfulCompanyPage.nodes[0];
  const partners = data.allContentfulCompanyPage.nodes[0].partners;
  const bullets = data.allContentfulCompanyPage.nodes[0].bullets;

  const icons = [
    <AimOutlined style={{ fontSize: '40px', color: theme.colors.brandLight.primary }} />,
    <FieldTimeOutlined style={{ fontSize: '40px', color: theme.colors.brandLight.primary }} />,
    <TeamOutlined style={{ fontSize: '40px', color: theme.colors.brandLight.primary }} />,
    <PushpinOutlined style={{ fontSize: '40px', color: theme.colors.brandLight.primary }} />,
  ];
  return (
    <SiteLayout locale={pageContext.locale} componentName={pageContext.componentName}>
      <Seo
        title="Virksomhed"
        description="Privatcoronatest - Vi tilbyder skræddersyet virksomheds løsninger. Vi tilbyder COVID-19 prøvetagning for virksomheder. Vi benytter de mest præcise test på markedet."
        lang={pageContext.locale}
        keywords={[
          'COVID-19',
          'Corona',
          'Privat',
          'Test',
          'Privatcoronatest',
          'Privat corona test',
          'Privat coronatest',
          'Danmark',
          'DK',
          'corona',
          'test',
          'organisation',
          'privat testing',
          'coronavirus',
          'corona test privat',
          'corona test firma',
          'test virksomhed',
        ]}
        pathname="company"
      />
      <Row
        style={{background: 'white' }}
        align="middle"
        >
        <Col xs={{ span: 24, offset: 0 }} lg={{ span: 11, offset: 0}}>
          <CompanyImg aria-label={content.companyImage.title } fluid={content.companyImage.fluid} alt={content.companyImage.title} />
        </Col>
        <Col sm={{ span: 24, offset: 0 }} lg={{  span: 10, offset: 2 }}>
        <StyledHead>{content.title}</StyledHead>
          <Row gutter={[32, 32]} style={{padding: '0.3rem'}}>
            {bullets &&
              bullets.map((bullet, key) => (
                <Col
                  xs={{ span: 12, offset: 0 }}
                  sm={{ span: 12, offset: 0 }}
                  key={key}
                  style={{ textAlign: 'center' }}
                >
                  {icons[key]}
                  <div style={{color: theme.colors.brandLight.primary}}
                    dangerouslySetInnerHTML={{
                      __html:
                        bullet.childContentfulBulletpointTextRichTextNode
                          .childContentfulRichText.html,
                    }}
                  />
                </Col>
              ))}
          </Row>
        </Col>
      </Row>

      <Row
        style={{paddingTop: '3rem', paddingBottom: '1.5rem' }}
      >
        <Col span={20} offset={2}>
          <H2Bold style={{textAlign: 'center'}}>{pageContext.locale === "da-DK" ? "Det siger vores kunder" : "Customer reviews"}</H2Bold>
          <ReviewCarousel
            title={""}
            reviews={content.companyReviews}
          />
        </Col>
      </Row>

      <Row gutter={[0, 20]}
              style={{paddingTop: '1rem', paddingBottom: '1.5rem', background: theme.colors.brandLight.light }}
              >
        <Col span={20} offset={2} style={{ paddingTop: '3rem' }}>
          <FadeInHeader>
            <StyledHeadline>{content.packageTitle}</StyledHeadline>
          </FadeInHeader>
        </Col>
      </Row>
      <Row style={{paddingTop: '1rem', paddingBottom: '1.5rem', background: theme.colors.brandLight.light }}>
        <Col sm={{ span: 22, offset: 1 }} lg={{ span: 24, offset: 0 }}>
          <Row justify="center" gutter={[12, 50]}>
            {content.packages &&
              content.packages.map((c: IPriceCard, key) => (
                <Col xs={22} sm={12} lg={5} key={key} >
                  <PriceingCard locale={pageContext.locale} key={key} priceCard={c} />
                </Col>
              ))}
          </Row>
        </Col>
        <Col span={24} style={{ textAlign: 'center', marginTop: '2rem' }}>
          <TextXsmall>{pageContext.locale === 'da-DK' ? 'Alle priser er eksklusiv moms' : 'All prices are exclusive of VAT'}</TextXsmall>
        </Col>
      </Row>

      <FadeInContainer>
        <Row style={{ background: 'white' }}>
          <Col span={20} offset={2}>
            <Partners partners={partners} />
          </Col>
        </Row>
      </FadeInContainer>

    </SiteLayout>
  );
};

export default CompanyPage;

export const query = graphql`
  query CompanyQuery($locale: String) {
    allContentfulCompanyPage(filter: { node_locale: { eq: $locale } }) {
      nodes {
        title
      companyImage {
        fluid(maxWidth: 1000) {
          ...GatsbyContentfulFluid
        }
      }
      bullets {
        childContentfulBulletpointTextRichTextNode {
          childContentfulRichText {
            html
          }
        }
      }
      companyReviews {
        name
        text {
          text
        }
        company
      }
      packageTitle
      packages {
        title
        price
        linkButton
        description {
          description
        }
        features
        highlight
      }
      partners {
        title
        partner {
          companyName
          img {
            fluid(maxWidth: 400) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
      }
    }
  }
`;
