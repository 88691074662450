import VisibilitySensor from "react-visibility-sensor";

import { animated, useSpring } from "react-spring";
import React, { useState } from "react";

const FadeInDirection = ({ isVisible, children }: any) => {
    const props = useSpring({
      opacity: isVisible ? 1 : 0,
      transform:  isVisible ? 'translateY(0px)' : 'translateY(-50px)'
    });
    return <animated.div style={props}>{children}</animated.div>;
  };
 

const FadeInHeader: React.FC = (props) => {
    const [isVisible, setVisibility] = useState(false);
  
    const onChange = (visiblity: boolean) => {
      visiblity && setVisibility(visiblity);
    };

    return (
      <VisibilitySensor onChange={(v) => onChange(v)} partialVisibility={true} scrollDelay={500} intervalDelay={500} offset={{top:200, bottom: 200}}>
        <FadeInDirection isVisible={isVisible}>{props.children}</FadeInDirection>
      </VisibilitySensor>
    );
  };
  
 export default FadeInHeader